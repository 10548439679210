<template lang="pug">
  div.row
    div.col-12
      div.table-responsive
        table.table.table-striped.targets-table
          thead
            tr
              th Valid
              th.link(@click="get_order('id')") ID
              th.link(@click="get_order('user')")
                span(data-feather="user")
              th No.
              th
                span(data-feather="eye")
              th.site-label Label
              th URL
              th
                span(data-feather="image")
              th
                span(data-feather="clock")
          tbody
            tr.pointer(v-for="t in targets", :key="t.id", @click="history(t.id)")
              td <svg v-show="t.valid" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-circle" data-v-12cac6b8=""><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
              td {{ t.id }}
              td {{ t.user_id }}
              td {{ t.user_site_id }}
              td {{ t.node_id }}
              td.site-label {{ t.label }}
              td 
                a(:href="t.url", target="_blank", rel="noopener") <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-external-link" data-v-12cac6b8=""><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg>
              td <svg v-show="t.ss" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-image" data-v-12cac6b8=""><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><circle cx="8.5" cy="8.5" r="1.5"></circle><polyline points="21 15 16 10 5 21"></polyline></svg>
              td {{ t.interval }}
      nav(v-if="pages>1")
        p.mb-1.text-center {{ PER_PAGE*(page-1)+1 }} - {{ Math.min(PER_PAGE*page, count) }} / {{ count }}
        ul.pagination.justify-content-center
          li.page-item(:class="page===1? 'disabled':''")
            a.page-link(@click="get_page(this.page-1)") Previous
          li.page-item(v-for="i in pages", :class="page===i? 'active':''")
            a.page-link(@click="get_page(i)") {{ i }}
          li.page-item(:class="page===pages? 'disabled':''")
            a.page-link(@click="get_page(this.page+1)") Next
</template>

<script>
import feather from 'feather-icons';

export default {
  name:"Targets",
  data (){
    return {
      PER_PAGE:100,
      count:8,
      page:1,
      order:"id",
      desc:false,
      targets:[
      ],
      user_id:0
    }
  },
  computed :{
    pages (){
      if (this.PER_PAGE)
        return Math.ceil(this.count / this.PER_PAGE);
      else
        return 1;
    },
  },
  mounted (){
    feather.replace();
    if ("user" in this.$route.query)
      this.user_id = Number(this.$route.query.user);
    this.get();
  },
  methods: {
    get (){
      this.axios.get(`/api/targets?per_page=${this.PER_PAGE}&page=${this.page}&order=${this.order}${this.desc? '&desc':''}${this.user_id? `&user=${this.user_id}`:''}`)
      .then((response) => {
        this.count = response.data.count;
        this.page = response.data.page;
        this.targets = response.data.targets;
      })
    },
    get_page (page){
      this.page = page;
      this.get();
    },
    get_order (order){
      this.order = order;
      this.get();
    },
    history (id){
      this.$router.push({ path:"/histories", query:{ id:id } });
    }
  }
}
</script>

<style lang="scss" scoped>
.targets-table {
  th, td {
    vertical-align: middle;
    text-align: center;
  }
  .site-label {
    text-align: left;
  }
}
.link {
  color: blue;
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

</style>